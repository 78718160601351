.nav--top {
  display: none;
  text-align: right;
  margin-bottom: 2em;
}

@media (min-width: 1024px) {
  .nav--top {
    display: block;
  }
}

.header.sticky .nav--top {
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.nav--top__item {
  display: inline-block;
  position: relative;
  text-underline-offset: 2px;
  transition:
    text-underline-offset 0.3s;
}

.nav--top__item + .nav--top__item {
  margin-left: 1.5em;
}

@media (hover: hover) {
  .nav--top__item:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
