.footer {
  position: relative;
  z-index: 1081;
  text-align: center;
  margin-top: -3px;
  padding-top: 1px;
}

.footer-branding {
  height: 100px;
  margin-top: 20px;
  margin-top: 12vh;
}

.footer-branding__item {
  bottom: 0;
}

.footer-branding.animated .footer-branding__item {
  transition: transform 2.2s ease;
}

.footer-branding__item:first-of-type {
  transform: translateY(20%);
}

.footer-branding__item:nth-of-type(2) {
  transform: translateY(15%);
}

.footer-branding__item:nth-of-type(3) {
  transform: translateY(8%);
}

.footer-branding.animated .footer-branding__item:nth-of-type(2) {
  transition: transform 2s ease;
}

.footer-branding.animated .footer-branding__item:nth-of-type(3) {
  transition: transform 1.9s ease;
}

.footer-branding.animated .footer-branding__item {
  transform: translateY(0);
}

.footer-branding__item:nth-of-type(3) {
  bottom: 1px;
}

.footer-branding__item:last-of-type {
  bottom: 2px;
}

@media (min-width: 600px) {
  .footer-branding {
   height: 100px;
   margin-top: 100px;
  }
}

@media (min-width: 900px) {
  .footer-branding {
   height: 160px;
   margin-top: 160px;
   margin-top: 30vh;
  }
}

@media (min-width: 1200px) {
  .footer-branding {
   height: 220px;
   margin-top: 220px;
   margin-top: 40vh;
  }
}

@media (min-width: 1600px) {
  .footer-branding {
   height: 350px;
   margin-top: 350px;
   margin-top: 45vh;
  }
}
.footer-newsletter-button {
  margin-top: 1em;
}
@media (min-width: 600px) {
  .footer-newsletter-button {
    margin-top: -30px;
  }
}

@media (min-width: 1200px) {
  .footer-newsletter-button {
    margin-top: -60px;
  }
}
