.breadcrumb {
  margin-top: .125rem;
  font-weight: 400;
  border-bottom: 1px solid var(--color-primary-09);
  padding-bottom: 0.75em;
  font-size: 0.875rem;
}

.breadcrumb__icon {
  width: 12px;
  margin-top: -2px;
}

.breadcrumb__link::after {
  content: '〉';
  font-size: 12px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 1em;
}
.breadcrumb__link.last::after {
  content: '';
}
.breadcrumb__link {
  display: inline-block;
  padding: 0;
  margin-top: 12px;
  margin-right: 0.25em;
}

@media (min-width: 768px) {
  .breadcrumb {
    padding-bottom: 12px;
  }
  .breadcrumb__link {
    margin-right: 0.625em;
  }
}

a.breadcrumb__link:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
