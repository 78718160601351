@media print {
  .header,
  .nav,
  .contact-box,
  .footer,
  .footer-branding,
  .top-notification {
    display: none;
  }
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  body {
    min-width: 992px !important;
    font-size: 0.875rem;
    font-family: "Pluto Sans", 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
      Helvetica, Arial, 'Lucida Grande', sans-serif;
  }
  ul li {
    list-style: disc;
  }
  ul li::before {
    content: none;
  }
  @page { 
    size: a4;
    margin: 1cm .75cm; 
  }
  .container {
    min-width: 992px !important;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  h1 {
    font-size: 1.5rem;
  }
  .icon svg {
    width: 1em;
    height: 1em;
  }
  .box-shadow {
    box-shadow: none !important;
    border: 1px solid var(--color-primary-2);
  }
  .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3 {
    width: 25% !important;
  }
  .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4 {
    width: 33.3333333333% !important;
  }
  .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6 {
    width: 50% !important;
  }
  .br-9 {
    border-radius: 0 !important;
  }
  .button {
    background-color: white !important;
    color: var(--color-primary) !important;
  }
  .image-gallery-icon {
    display: none !important;
  }
  .print-4 {
    max-width: 25% !important;
  }
}
