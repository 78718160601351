.checkout-header {
  height: 54px;
  background-image: linear-gradient(to bottom, #ffffff, #f8fafc);
}

.checkout-logo {
  max-width: 126px;
  vertical-align: middle;
  margin-top: 1.125em;
  margin-bottom: 1.125em;
}

.checkout-footer {
  font-size: small;
  margin-top: 3rem;
  padding-bottom: 3rem;
  color: #3f5262;
  background-image: linear-gradient(to bottom, #eff3f7, #ffffff);
}

@media (min-width: 768px) {
  .checkout-header {
    height: 82px;
  }
  .checkout-logo {
    max-width: 226px;
    margin-top: 0 !important;
    margin-bottom: 0;
  }
  .checkout-footer {
    font-size: inherit;
  }
}

.checkout-footer {
}

@media (max-width: 1023px) {
  .checkout-footer .nav--footer {
    text-align: center;
  }
  .checkout-footer .content {
    flex-direction: column;
  }
}
