.call-to-action-area {
  text-align: center;
  margin: 1.5em 0 4em;
  padding: 1.25rem 5px 2rem;
  background-image: linear-gradient(to top, #fcfdfe, #f3f6f9);
  border-bottom: 1px solid rgba(63, 82, 98, 0.11);
}

.call-to-action-area h2 {
  margin-top: 1rem;
}

.call-to-action-area p {
  max-width: 500px;
  margin: 0 auto 1.5em;
  display: block;
}
