:root {
  --color-primary: #003a4f;
  --color-primary-03: rgba(0, 58, 79, 0.03);
  --color-primary-06: rgba(0, 58, 79, 0.06);
  --color-primary-09: rgba(0, 58, 79, 0.09);
  --color-primary-2: rgba(0, 58, 79, 0.2);
  --color-primary-5: rgba(0, 58, 79, 0.5);
  --color-primary-7: rgba(0, 58, 79, 0.7);
  --color-primary-75: rgba(0, 58, 79, 0.75);
  --color-blue-1: #ddf0fe;
  --color-blue-2: #b5e2f9;
  --color-blue-3: #87d1f6;
  --color-blue-4: #41c0f0;
  --color-blue-5: #4292d7;

  --color-pink-5: #DD46A0;

  --color-green: #afca0B;
  --color-green-lightest: #ECF2D0;
  --color-greeen-dark: #649153;
  --color-white: #ffffff;

  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

html {
  box-sizing: border-box;
  margin: 0;
  overscroll-behavior: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {  
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.4;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

a:focus-visible {
  border-radius: 3px;
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border: 2px solid #649153;
  box-shadow: 0 0 0 30px #dae49b inset !important;
}

small {
  font-size: 0.875rem;
  line-height: 1.25;
}

p, ul, ol, .paragraph {
  line-height: 1.6;
  margin-top: 0.75rem;
}

@media (min-width: 1024px) {
  p, ul, ol, .paragraph {
    font-size: 1.125rem;
    line-height: 1.72;
  }
}


h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-weight: 400;
}

h1 {
  font-size: 1.875rem;
  font-weight: 600;
  margin-top: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 2.8125rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.44;
}

h5, h6 {
  font-size: 1rem;
  line-height: 1.4;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 2.4375rem;
  }
}

input,
textarea {
  font-size: initial;
}

hr {
  margin: 20px 0;
  border-top: 1px solid var(--color-primary-09);
  border-bottom: 0;
  box-sizing: border-box;
}

ul li {
  list-style: none;
  position: relative;
}

ol li {
  position: relative;
}

 li::before {
  display: inline-block;
  background-image: url(/static/img/icon-streamline/Aufzaehlung-Punkt.svg);
  background-repeat: no-repeat;
  background-position: top left;
  width: 1em;
  height: 1em;
  content: '';
  position: absolute;
  left: -1.25em;
  top: .25em;
}

ol li::before {
  background-image: initial;
}

.iframe {
  border: none;
}

/* todo:
  - merge "content, container"
  - use consistent rule for content from cms
  */

.content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  word-break: break-word;
}

.content img {
  margin-top: 0.75rem;
  height: auto;
}

.content p {
  margin-bottom: 0;
}

/* cms content in tables needs to be smaller */
.content td p,
.content td ul {
  font-size: 1rem;
  line-height: 1.44;
}

.content h2,
.content h3,
.content h4,
.content h5 {
  margin-top: 2.25rem;
}

.content > h4 {
  margin-bottom: 0;
  margin-top: initial;
}

.content p a,
.container p a {
  text-decoration: underline;
  text-underline-offset: 3px;
  position: relative;
  display: inline-block;
  transition:
    text-underline-offset 0.3s;
}
@media (hover: hover) {
  .content p a:hover,
  .container p a:hover {
    text-underline-offset: 4px;
  }
}

@media (min-width: 768px) {
  .content {
    max-width: 726px;
  }
}

@media (min-width: 1024px) {
  .content {
    max-width: 820px;
  }
}
