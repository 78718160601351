.nav--side .sticky-top {
  top: 5em;
}

.nav--side__link {
  display: inline-block;
  margin-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-underline-offset: 2px;
  transition:
    text-underline-offset 0.3s;
}

@media (max-width: 767px) {
  .nav--side {
    margin-bottom: 1em;
  }
  .nav--side__link {
    font-size: .875rem;
  }
  .nav--side__link.is-parent,
  .nav--side__link.border-bottom {
    line-height: 1;
    padding-right: .5em;
    border-right: 1px solid var(--color-primary-09);
  }
}

@media (min-width: 768px) {
  .nav--side {
    min-width: 205px;
    margin-right: 32px;
    max-width: 210px;
    margin-top: 2.25em;
  }
  .nav--side__link {
    display: block;
  }
  .nav--side__link.is-parent,
  .nav--side__link.border-bottom {
    border-bottom: 1px solid var(--color-primary-09);
  }
}

@media (min-width: 1024px) {
  .nav--side {
    min-width: 264px;
    font-size: initial;
  }
}

.nav--side__link:hover,
.nav--side__link:focus,
.nav--side__link[data-in-navigation-path='true'] {
  text-decoration: underline;
  text-underline-offset: 4px;
}
