.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.35);
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2.5em;
}

.popup-close {
  position: absolute;
  right: 1.5em;
  top: 1em;
}

.popup-advert {
  position: absolute;
  top: 3em;
  width: 95vw;
  left: 2.5vw;
}

.popup-advert-close {
  position: absolute;
  right: 1em;
  top: -1.8em;
  z-index: 9999999;
  background: #fff;
  border-radius: 50%;
  line-height: 0;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (min-width: 900px) {
  .popup-advert {
    top: 50%;
    left: 50%;
    min-width: 400px;
    max-width: 700px;
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .popup-advert-close {
    right: 0;
  }
}

.popup .icon--close svg {
  width: 17px !important;
  height: 17px !important;
}

@media (max-width: 767px) {
  .popup {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1em;
    transform: translate(0%, 0%);
    overflow-y: auto;
  }
  .popup-close {
    position: relative;
    margin-top: -1em;
    margin-left: 1em;
    margin-bottom: 2em;
    display: block;
  }

}
