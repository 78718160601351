.cookie-monster-wrap {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  border-top: 1px solid var(--color-primary-09);
}

.cookie-monster-wrap.hidden,
.hidden-cookies .cookie-monster {
  display: none;
}

.cookie-monster {
  background-image: linear-gradient(to top, #ffffff, #f8fafc);
}

.cookie-monster__text {
  line-height: 1.33;
}

/* Cybot cookiebot style overrides */

a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  padding-top: 3px !important;
  color: #ffffff !important;
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  border-radius: 2px !important;
}

#CybotCookiebotDialog,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {
    color: var(--color-primary) !important;
}


#CybotCookiebotDialog {
  box-shadow:
    0 -10px 15px -3px rgba(0,0,0,.1),
    0 -4px 6px -2px rgba(0,0,0,.05),
    0 0 0 1px rgba(0,0,0,.05) !important;
  background-color: #f8fafc !important;
  background-image: linear-gradient(180deg,#fff,#f8fafc) !important;
}

#CybotCookiebotDialogDetail {
  background-color: #f8fafc !important;
  background-image: linear-gradient(180deg,#fff,#f8fafc) !important;
}

#CybotCookiebotDialogPoweredbyImage {
  width: 36px !important;
  opacity: .8;
}

#CybotCookiebotDialogDetailFooter {
  background-color: #ffffff !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper a {
  border-radius: 9px;
}
