/* Styling of src/components/portal/PortalWelcome.js */
.PortalWelcome__breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.875rem;
  color: #ffffff;
  line-height: 30px;
}

.PortalWelcome__breadcrumbsHolder {
  background-color: #194d60;
}

.PortalWelcome__customerNumber {
  display: block;
  font-size: 1rem;
  line-height: 0;
  margin-top: .5em;
  text-align: left;
}

.PortalWelcome__title {
  margin: 0;
  padding: 32px 0;
  font-size: 30px;
  line-height: 38px;
  color: #fff;
  text-align: left;
}
