.zindex-1000,
.zindex-dropdown {
  z-index: 1000;
}
.zindex-1020,
.zindex-sticky {
  z-index: 1020;
}
.zindex-1030,
.zindex-fixed {
  z-index: 1030;
}
.zindex-1040,
.zindex-modal-backdrop {
  z-index: 1040;
}
.zindex-1050,
.zindex-modal {
  z-index: 1050;
}
.zindex-1060,
.zindex-popover {
  z-index: 1060
}
.zindex-1070,
.zindex-tooltip {
  z-index: 1070
}
