.top-banner {
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;

  line-height: 20px;
  color: var(--color-primary);
}

.top-banner.maintenance {
  background-color: #f6c800;
}

.top-banner > div {
  display: flex;
  align-items: center;
  max-width: 640px;
}

.top-banner .icon svg {
  height: 48px;
  width: 72px;
  margin-right: 15px;
}

.top-banner a {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 4px;
}
