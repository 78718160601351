.product-hero {
  z-index: 11;
}

.product-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1.5em 0;
}

.product-link {
  color: var(--color-primary);
  line-height: 20px;
  border-bottom: 1px solid var(--color-primary);
}

.product-box-icon {
  min-width: 21px;
  height: 21px;
}

.product-box-icon.icon-check svg {
  width: 12px;
  height: 12px;
}

.product-short-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
}

.product-short-row-label {
  width: 136px;
  font-weight: 400;
}

.product-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  border-top: 1px solid var(--color-primary-09);
  border-bottom: 1px solid var(--color-primary-09);
}

.product-details > div {
  width: 100%;
}

@media (min-width: 1024px) {
  .product-details > div:first-of-type {
    width: 24%;
  }
  .product-details > div:last-of-type {
    width: 72%;
  }
}

.product-details-row {
  display: flex;
  margin-bottom: 12px;
}
.product-details-row:last-of-type {
  margin-bottom: 0px;
}

.product-details-row > div {
  width: calc(100% / 2);
}
.product-details-row > div:first-of-type {
  margin-right: 30px;
}

@media (min-width: 1024px) {
  .product-details-row > div:first-of-type {
    width: calc(100% / 3);
    margin-right: 0;
  }
}

.product-details h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: bold;
}

.product-details-row .trigger {
  display: inline-block;
}

.select-product-details select {
  min-width: 164px;
  padding: 5px 15px;
}
.select-product-details::after {
  top: 12px;
}

.product-cutting-icon {
  background: #e8f499;
  border-radius: 20px;
  padding: 5px;
}

.delivery-time-text {
  display: block;
  margin-top: 16px;
  margin-bottom: 24px;
  max-width: 360px;
}

.loader-wrap .arrow-long {
  margin-right: 0;
}

.product-fav-list {
  border-top: 1px solid var(--color-primary-09);
  padding-top: 1rem;
  margin-top: 1rem;
}

.product-fav-list .us-dropdown-box {
  max-width: none;
}

.product-fav-list .us-dropdown-footer {
  text-align: right;
}

.product-filter .select-wrap {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.product-current-client {
  font-size: 1.125rem;
  font-weight: 600;
}

.product-change-cart {
  color: var(--color-primary);
  font-size: 0.875rem;
  line-height: 16px;
  border-bottom: 1px solid var(--color-primary);
}
