.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-3 {
  margin-top: -1rem;
}

.-mt-4 {
  margin-top: -1.25rem;
}

.br-9 {
  border-radius: 9px;
}

.br-b-9 {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.circles-before-title {
  background-color: #00c0ba;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  position: absolute;
  color: transparent;
  bottom: -8px;
  z-index: -1;
}

.circles-before-title::before,
.circles-before-title::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}

.circles-before-title::before {
  background-color: #72d0ea;
  width: 24px;
  height: 24px;
  left: -8px;
  top: 28px;
}

.circles-before-title::after {
  background-color: #b3da68;
  width: 32px;
  height: 32px;
  left: 26px;
  top: 10px;
}

.title-with-circle-1,
.title-with-circle-2,
.title-with-circle-3 {
  position: relative;
}

.title-with-circle-1::before,
.title-with-circle-2::before,
.title-with-circle-3::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.title-with-circle-1::before {
  background-color: #72d0ea;
  width: 24px;
  height: 24px;
  left: -5px;
  top: 10px;
}

.title-with-circle-2::before {
  background-color: #00c0ba;
  width: 46px;
  height: 46px;
  left: -8px;
  top: -9px;
}

.title-with-circle-3::before {
  background-color: #b3da68;
  width: 32px;
  height: 32px;
  left: 6px;
  top: -6px;
}

@media (min-width: 768px) {
  .ml-md-150px {
    margin-left: 150px;
  }
}

.left-0 {
  left: 0;
}

.box-shadow {
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  transition-property: box-shadow !important;
  transition-duration: 0.16s !important;
  transition-timing-function: ease-in-out !important;
}

.box-block {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.box-image-wrap {
  padding-top: 56.5%;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.box-image-wrap-narrow {
  padding-top: 34%;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.box-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.2s;
  object-fit: cover;
  margin-top: 0 !important;
}

@media (hover: hover) {
  .box-block:hover .box-image {
    transform: scale(1.06);
  }
}

.label-opacity-78 .migel-label {
  opacity: 0.78;
}

.opacity-78 {
  opacity: 0.78;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1;
}

.font-size-0 {
  font-size: 0;
}

.font-size-big {
  font-size: 1.125rem;
}

.font-size-1,
.font-size-1 * {
  font-size: 1rem !important;
}

.font-size-inbetween {
  font-size: 0.875rem;
}

.font-size-small {
  font-size: 0.75rem;
}

.font-size-smaller {
  font-size: 0.675rem;
}

.font-size-normal {
  font-size: 1em;
}

@media (min-width: 576px) {
  .font-size-sm-normal {
    font-size: 1em;
  }
}

.h3 {
  font-size: 1.25rem;
}

.h4 {
  font-size: 1.125rem;
}

.h5 {
  font-size: 1rem;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.h-max-44px {
  max-height: 44px;
}

.h-max-80px {
  max-height: 80px;
}

.w-max-80px {
  max-width: 80px;
}

@media (max-width: 767px) {
  .h-max-80px {
    max-height: 44px;
  }

  .w-max-80px {
    max-width: 44px;
  }
}

.w-max-640px {
  max-width: 640px;
}

.w-140px {
  width: 140px;
}

.border-bottom-fat {
  border-bottom: 3px solid var(--color-primary-09);
}

.border-bottom-active {
  border-bottom: 3px solid var(--color-primary);
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-revert > * {
  /* added in order to counter next image component injecting overflow: hidden by default */
  overflow: revert !important;
}

.max-height-content {
  max-height: calc(100vh - 71px);
}

@media (max-width: 1023px) {
  .remove-extra-side-space {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px) !important;
  }
}

.remove-extra-side-space--4-all {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px) !important;
}

.color-light {
  color: rgba(0, 58, 79, 0.65) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.color-green-dark {
  color: var(--color-greeen-dark) !important;
}

.color-pink-5 {
  color: var(--color-pink-5) !important;
}

.color-blue {
  color: var(--color-primary) !important;
}

.color-blue-4 {
  color: var(--color-blue-4) !important;
}

.color-bg-green {
  background-color: var(--color-green) !important;
}

.color-bg-green-light {
  background-color: #c5d75d !important;
}
.color-bg-green-lighter {
  background-color: #dae49b !important;
}
.color-bg-green-lightest {
  background-color: var(--color-green-lightest) !important;
}

.color-bg-blue {
  background-color: var(--color-primary) !important;
}

.color-bg-blueish {
  background-color: #194d60 !important;
}

.color-bg-blueish-light {
  background-color: #205b70 !important;
}

.color-bg-blueish-lightest {
  background-color: #cbdbdf !important;
}

.color-bg-blue-90 {
  background-color: rgba(0, 58, 79, 0.9) !important;
}

.color-bg-blue-light {
  background-color: var(--color-primary-06) !important;
}

.color-bg-primary-03 {
  background-color: var(--color-primary-03) !important;
}

.color-bg-blue-lighter {
  background-color: rgba(0, 58, 79, 0.02) !important;
}

.color-white {
  color: #ffffff;
}

.color-bg-white {
  background-color: white !important;
}

.color-bg-white-gradient {
  background: linear-gradient(
    180deg,
    #ffffff 88%,
    rgba(255, 255, 255, 0.5) 95%,
    rgba(255, 255, 255, 0) 100%
  );
}

.color-bg-orange {
  background-color: #ffc107;
}

.color-bg-orange-lightest {
  background-color: #fee7c8;
}

.color-orange-dark {
  color: #d17634;
}

.color-bg-red {
  background-color: #da4220;
}

.link {
  font-weight: 400;
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  border-radius: 3px;
  transition: text-underline-offset 0.3s;
  word-break: break-word;
}

.link-hover {
  text-underline-offset: 2px;
  transition: text-underline-offset 0.3s;
}

@media (hover: hover) {
  .link:hover,
  .link-hover:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.link:focus-visible,
.link-hover:focus-visible {
  outline: 2px solid #79939b;
  outline-offset: 4px !important;
}

.tabindexed:focus-visible {
  outline: 2px solid #79939b;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .width-md-75 {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .hide-on-small-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-on-not-small-mobile {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-on-not-mobile {
    display: none !important;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}

.error-message {
  color: #e05b5b;
}

.form-check-label.text-danger::before {
  border-color: #e05b5b;
}

.border-color-red {
  border-color: #e05b5b
}

.error-message ul {
  margin-left: -2.5rem;
}

.error-message a {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.form-errors-item {
  vertical-align: middle;
  line-height: 1.3;
  font-size: 1rem;
}

.card {
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.bold {
  font-weight: 700;
}

.border-horizontal {
  border-top: 1px solid var(--color-primary-09);
  border-bottom: 1px solid var(--color-primary-09);
}

.border-horizontal + .border-horizontal {
  border-top: 0;
}

.has-links a {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.no-wrap {
  white-space: nowrap;
}

.line-height-1-22 {
  line-height: 1.22;
}

.dangerously-set-html * {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.22;
}

.dangerously-set-html li {
  line-height: 1.5;
}

.dangerously-set-html em {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  display: block;
}

.loader-wrap {
  display: block !important;
  margin-top: 3em;
  margin-bottom: 3em;
}

.loader-wrap svg {
  display: block;
  margin: 1em auto !important;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  width: 280px;
  background-color: #fff;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.05);
  opacity: 1;
  visibility: visible;
  border-radius: 9px;
  padding: 0.375em;
  z-index: 10;
}

@media (min-width: 768px) {
  .dropdown {
    width: 352px;
    top: 46px;
  }
}

.dropdown--user {
  width: auto;
  min-width: 238px;
  left: 0;
  right: initial;
}

.dropdown__link {
  display: inline-flex;
  width: 100%;
  line-height: 1;
  padding: 0.75em 1em;
  font-size: 1rem;
  border-radius: 9px;
}

.dropdown__link + .dropdown__link {
  margin-top: 0.125rem;
}

.dropdown__link:hover,
.dropdown__link:focus-visible {
  background-color: #ecf2d0;
}

.dropdown__link--logout {
  padding-right: 1em;
  padding-top: 0.75em;
  margin-top: 0.5em;
  margin-bottom: 0.375em;
  margin-right: -0.875em;
  margin-left: -0.875em;
  border-top: 1px solid var(--color-primary-09);
}

.overide-default-image-properties span,
.overide-default-image-properties div {
  position: relative !important ;
}
.story-detail-img {
  position: inherit !important ;
  width: auto !important;
  height: auto !important;
}

.min-width-100 {
  min-width: 100%;
}

.min-width-auto {
  min-width: auto !important;
}
.min-height-auto {
  min-height: auto !important;
}

.product-image-modal-container {
  width: 100%;
  max-height: 800px;
}

.product-image-modal-container img {
  height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
}

.environment-indicator {
  background: #ea5098;
  color: #fff;
  padding: 0.25em 0.375em 0.125em;
  border-radius: 9px;
  font-weight: bold;
  position: absolute;
  top: 30px;
}

.color-success {
  color: #649153 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.object-fit-cover {
  object-fit: cover;
}

.object-position-left {
  object-position: left;
}

.fade-in-out.visible {
  opacity: 1;
  transition: opacity 0.72s ease-in-out;
}

.fade-in-out.hidden {
  opacity: 0;
}

/* Hack for layout and dom renderer generated next/image components */

.force-position-relative {
  position: relative !important;
}

h1 + .rich-text-component {
  margin-block: 0 !important;
}

figure + .rich-text-component {
  margin-top: 0 !important;
}

.cms h1 {
  margin-bottom: 0.75rem;
}

.rich-text-component h1:first-of-type {
  margin-top: 0;
}

.aspect-ratio-1 {
  aspect-ratio: 1;
}


.hin-link img {
  width: 72px;
}
