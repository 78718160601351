.filter-card {
  padding: 0.875em;
  background-color: rgba(0, 58, 79, 0.05);
}

.filter-card__item {
  line-height: 1.2;
}

.filter-card__item-checkbox::before {
  content: '';
  display: inline-block;
  border: 1px solid var(--color-primary);
  width: 16px;
  height: 16px;
  vertical-align: top;
  border-radius: 3px;
}

.filter-card__item[active] .filter-card__item-checkbox::before {
  content: '';
  background-image: url(/static/img/icon-streamline/check-blue.svg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-card__item-indicator {
  color: #7997a2;
}

.filter-card__more {
  text-decoration: underline;
  text-underline-offset: 4px;
}

@media (max-width: 767px) {
  .assortment-filter {
    margin-left: -1em;
    margin-right: -1em;
    width: calc(100% + 2em);
    max-height: calc(100vh - 8.5em);
    overflow-y: auto;
    border-top: 1px solid #d8d8d8;
  }

  .assortment-filter .filter-card__item {
    margin-bottom: 0.75em;
  }

  .assortment-filter .filter-card__more {
    font-size: 0.875rem;
  }
}
