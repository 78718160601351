.checkout-form {
  position: relative;
  max-width: 960px;
}

.checkout-form:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  content: '';
  display: inline-block;
  width: 1px;
  background: #e2e2e2;
}

.co-client-chooser {
  margin-bottom: 2rem;
}

.co-select-recipe h5 {
  margin-top: 0;
}

.co-select-recipe .select-wrap {
  margin-bottom: 1rem;
}

.co-select-recipe .select-wrap,
.co-select-recipe .select-wrap select {
  width: 100%;
}

.co-no-recipes-info {
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: block;
}

.co-no-recipes-info .button {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.co-select-recipe form {
  padding: 0;
}

.co-delivery {
  position: relative;
}

.co-delivery .field-container {
  position: relative;
}

.co-delivery .co-address .field-container {
  padding: 0;
}

.co-delivery .iframe {
  width: 100%;
  margin-top: 1rem;
}

.co-new-address {
  padding: 1rem;
  border-radius: 3px;
  background: #f9f9f9;
}

.co-new-address h2 {
  margin-top: 0;
}

.co-new-address .form-field {
  background-color: white;
}

.co-new-address .button-group {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .co-delivery .field-container > .form-label {
    left: 0;
    position: absolute;
    top: .25rem;
    margin-bottom: 0;
    max-width: 120px;
  }

  .co-delivery-fieldset .button {
    margin-left: 150px;
  }
  .co-delivery .field-container {
    padding-left: 150px;
  }

  .co-delivery .iframe,
  .co-delivery .co-new-address {
    margin-left: 150px;
  }

  .co-delivery .iframe {
    width: calc(100% - 150px);
  }

  .co-delivery .new-address-form .field-container {
    padding-left: 0;
    margin-top: 0px;
    margin-bottom: 1em;
  }

  .co-delivery .new-address-form .field-container > .form-label {
    left: 0;
    position: relative;
    margin-bottom: 8px;
    max-width: 100%;
  }

  .co-delivery .new-address-form .button {
    margin-left: 0;
  }
}

.co-select-customer p {
  margin-bottom: 0;
}

.checkout-overview-products-sent-info {
  margin-top: -13px;
  display: block;
}

@media (max-width: 440px) {
  #checkout-user-switch .switch__button-text {
    display: none;
  }
}

@media (min-width: 441px) {
  #checkout-user-switch .switch__button-text {
    max-width: 178px;
  }
}

.recipes__item {
  background-color: #f2f3f5;
}

.recipes__item .no-button.dropdown__link {
  line-height: initial;
  margin: 0 -0.5em;
  padding: 0.25em 0.5em;
}

.recipes__item .button.switch__button {
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
}



.product-availability-info {
  margin-left: 1em;
}

.product-availability-info::before {
  display: inline;
  content: '„ ';
}

.product-availability-info::after {
  display: inline;
  content: ' “';
}

.product-availability-info small {
  vertical-align: middle;
}


.box-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);;


}
.box-wrapper > div {
  max-width: 100%
}


@media ( max-width: 768px) {
  .box-wrapper {
    grid-template-columns: repeat(2, 1fr);;
  }

.box-wrapper :nth-child(4n) { grid-row: 1; }
.box-wrapper :nth-child(5n) { grid-row: 2; }
.box-wrapper :nth-child(6n) { grid-row: 3; }



}

