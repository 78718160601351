@media (max-width: 1299px) {
  .contact-bar {
    display: none;
  }
}

@media (min-width: 1300px) {
  .contact-bar {
    display: block;
    position: fixed;
    color: #ffffff;
    z-index: 1;
    transform: translate(1160px, 205px);
  }
}

.contact-bar__item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.10), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 3px rgba(0, 58, 79, 0.05);
}

.contact-bar__item div {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  background-color: var(--color-primary);
  border-radius: 20px;
  padding-right: 48px;
  padding-left: 1.5em;
  z-index: -1;
  font-weight: 600;
  opacity: .01;
  transform: translateX(20px);
  transition-property: transform, opacity;
  transition-duration: .24s;
  transition-timing-function: ease-in-out;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.10), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 3px rgba(0, 58, 79, 0.05);
}

.contact-bar__item:hover div {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
  white-space: nowrap;
}

.contact-bar__item div span {
  white-space: nowrap;
  line-height: 2.5;
}

.contact-bar__item + .contact-bar__item {
  margin-top: 8px;
}

.contact-bar__icon {
  width: 14px;
  height: 14px;
}
