.addrecipe .button {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}

.addrecipe-content {
  max-width: 600px;
}

.addrecipe-form {
  position: relative;
}

.addrecipe-form:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 14px;
  content: '';
  display: inline-block;
  width: 1px;
  background: #e2e2e2;
}

.addrecipe-articles-show-add-buttons,
.articles-heading-form,
.addrecipe-articles .article-list:not(:empty) {
  margin-bottom: 1.5rem;
}

.addrecipe-articles-show-add-buttons .button:first-child {
  margin-right: 1rem;
}

.addrecipe-articles .article-list {
  border: none;
}

.addrecipe-articles .article-list .article-item {
  box-shadow: none;
}

.addrecipe-articles textarea.form-control {
  outline: none;
  width: 100%;
  height: 8rem;
  border-color: #acacac;
  border-radius: 3px;
  resize: none;
  padding: 0.5rem;
}

.addrecipe-mediator .field-container {
  margin-bottom: 2rem;
}

.addrecipe-mediator #recipe-person-data-postalCode-field {
  padding-right: 14px;
}

.addrecipe-ss-header {
  border-bottom: 1px solid #e9e9e9;
  padding: 1rem 0;
}


.addrecipe-ss-title {
  margin: 0 0 0.25rem 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.addrecipe-ss-subtitle {
  margin: 0;
  font-size: 0.8rem;
}

.addrecipe-ss-button-wrapper {
  flex: 0 0 auto;
}

.addrecipe-ss-auto-form {
  padding: 1rem 0;
}

.addrecipe-ss-auto-form label:not(.form-label):not(.form-check-label) {
  font-size: 1.125rem;
  font-weight: bold;
}

.addrecipe-ss-auto-form .field-container {
  position: relative;
  margin: 2rem 0;
}

.addrecipe-ss-auto-form .field-container > .form-label {
  display: block;
  left: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.addrecipe-ss-auto-form input[type='radio'] + .form-label,
.addrecipe-ss-auto-form input[type='checkbox'] + .form-label {
  cursor: pointer;
  color: var(--color-primary);
  font-size: inherit;
}

.addrecipe-ss-auto-form .form-check {
  margin-bottom: 0.25rem;
}

.addrecipe-preview-inner {
  position: relative;
  padding-top: 100%;
  overflow: scroll;
}

@media (min-width: 768px) {
  .addrecipe-ss-auto-form .field-container {
    padding-left: 155px;
  }

  .addrecipe-ss-auto-form .field-container > .form-label {
    position: absolute;
    margin-bottom: 0;
    top: .3875em;
    max-width: 150px;
  }
}

.addrecipe-uploader .button {
  padding: 1rem;
  margin-top: -24px;
}

/* more */

.recipe-link:hover details,
.recipe-link:focus details {
  font-weight: bold;
}

.recipe-status::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  left: -24px;
  top: 50%;
  margin-top: -8px;
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.prescription .select-option::before {
  position: absolute;
  left: .75em;
  margin-top: 10px;
}

.prescription .select-option {
  padding-left: 2.5em;
  margin-left: 0;
}

.recipe--draft .recipe-status::before,
.recipe-status-sort--draft::before {
  background-color: #ea5098;
  border: 0;
}

.recipe--ready .recipe-status::before,
.recipe-status-sort--ready::before {
  background-color: #b2589e;
  border: 0;
}

.recipe--sent .recipe-status::before,
.recipe-status-sort--sent::before {
  background-color: #41c0f0;
  border: 0;
}

.recipe--final .recipe-status::before,
.recipe--first .recipe-status::before,
.recipe-declared.recipe-status::before,
.recipe-status-sort--final::before,
.recipe-status-sort--first::before {
  background-color: #C6E400;
  background-image: url(/static/img/icon-streamline/check-blue.svg);
  background-size: 75%;
  background-position: center center;
  border: 0;
  width: 1rem;
  background-repeat: no-repeat;
}

.recipe--archived .recipe-status::before,
.recipe-status-sort--archived::before {
  background-color: #194d60;
  border: 0;
}

.stepper-number {
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  font-size: 1rem;
  font-weight: 700;
  padding-top: 3px;
}

.stepper-title {
  margin-top: .25rem;
  font-size: .6875rem;
}

@media (min-width: 768px) {
  .stepper-number {
    width: 1.75em;
    height: 1.75em;
    font-size: 1.5rem;
  }
  .stepper-title {
    font-size: 1rem;
  }
}
