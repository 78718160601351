.next h3.section-title {
  margin: 20px 20px 20px 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-primary);
}

.next.portal-header {
  min-height: auto;
  padding-top: 0;
  margin: 0 auto;
}

.next .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.next .row .col {
  min-width: 15%;
}
.next .table-header {
  background-color: rgba(0, 58, 79, 0.04);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.next .table-header .item {
  margin: 9px 0;
}

@media (min-width: 768px) {
  .next .table-header .item:first-of-type {
    width: 25%;
  }
}

.next .table-header button {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 0.875rem;
  line-height: 18px;
  color: #6b7280;
  letter-spacing: 0.5px;
}
.next .table-header button svg {
  height: 8px;
  width: 8px;
  fill: #6b7280;
}
.next .table-header button[data-sorted] {
  outline: none;
  color: var(--color-primary);
}
.next .table-header button[data-sorted] svg {
  fill: var(--color-primary);
}

.next .row .icon--arrow-button-right {
  margin-right: 16px;
}
.next .row .icon--arrow-button-right svg {
  height: 14px;
  width: 16px;
}

.next .row .col.cart-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cart-items-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background: #853d75;
  border-radius: 50%;
}
.next .row .col.cart-items svg {
  height: 16px;
  width: 16px;
}

.clients-search-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 500px;
  max-width: 320px;
  width: 100%;
}

@media (max-width: 767px) {
  .clients-search-box {
    max-width: 100vw;
    max-height: calc(100vh - 2em);
  }
}


.next .clients-search {
  width: calc(100% + 30px);
  margin-right: -15px;
  padding-left: 15px;
  overflow-y: scroll;
}

.next .clients-search .client-list__item {
  width: unset;
  margin-right: 15px;
  padding: 8px 0;
}

.next .clients-search::-webkit-scrollbar {
  width: 12px;
}

.next .clients-search::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #d8d8d8;
}

.next .clients-search::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
}
