.no-button {
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  text-align: left;
  color: inherit;
  line-height: inherit;
  border-radius: 2px;
}

.no-button.text-center {
  text-align: center;
}

.no-button:focus-visible {
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group.align-right {
  align-items: center;
  flex-direction: row-reverse;
}

.button-group.align-right .button--primary {
  margin-right: 0;
}

.button-group.bordered {
  border-top: 2px solid var(--color-primary-09);
  border-bottom: 2px solid var(--color-primary-09);
  padding-top: 1em;
  padding-bottom: 0.25em;
  margin-bottom: 3em;
}

.button-group .button,
.button-group button {
  margin-right: 1em;
  margin-bottom: 1rem;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: calc(.5em + 1px) 1.2857125em .5em; /* font-related */
  text-align: center;
  border-radius: 0.6428571429em;
  border: 2px solid var(--color-primary);
  color: inherit;
  line-height: inherit;
  cursor: pointer;
  appearance: none;
  text-decoration: none !important;
  transition:
    background-color 0.08s ease-in-out,
    border-color 0.08s ease-in-out;
}

.button:focus-visible {
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

.button:active {
  outline: 0;
}

.button[disabled],
.button-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  outline: none;
  box-shadow: none;
}

.button--primary {
  color: #ffffff;
  background-color: var(--color-primary);
}

.button--primary:hover {
  background-color: #79939B;
  border-color: #79939B;
}

.button--primary:active {
  background-color: #AAB8BC;
  border-color: #AAB8BC;
}

.button--wider {
  min-width: 160px;
  white-space: nowrap;
}

.button--secondary {
  color: var(--color-primary);
  background-color: #ffffff;
}

.button--secondary:hover {
  background-color: #AAB8BC;
}

.button--secondary:active {
  background-color: #79939B;
}

.button--tertiary {
  background-color: var(--color-primary);
  border-color: #c5d75d;
  color: #c5d75d;
}

.button--tertiary:hover {
  background-color: #16473C;
}

.button--tertiary:active {
  background-color: #265437;
}

.button--round {
  width: 38px;
  height: 38px;
  border-radius: 20.2px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--dimmed {
  border-color: var(--color-primary-2);
  color: rgba(0, 58, 79, 0.8);
}

.button--dimmed:hover {
  border-color: var(--color-primary-5);
  color: inherit;
}

.button--secondary.opacity-78:hover,
.no-button.opacity-78:hover {
  opacity: 1;
}

.add-to-cart-wrapper {
  position: relative;
  display: flex;
}

.button-add-to-cart:focus-visible {
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

@media (any-pointer: coarse) {
  .add-to-cart-wrapper {
    display: inline-flex;
  }

  .add-to-cart-wrapper--with-counter:not(.add-to-cart-wrapper--fullwidth) {
    display: inline-flex;
  }

  .add-to-cart-wrapper--with-counter:not(.add-to-cart-wrapper--fullwidth)
    .add-to-cart-counter {
    width: 44px;
    margin-left: 44px !important;
    margin-right: 0;
    border-bottom: 2px solid var(--color-primary-5);
  }

  .add-to-cart-wrapper--with-counter:not(.add-to-cart-wrapper--fullwidth)
    .button-add-to-cart {
    margin-left: 44px;
    border-top-right-radius: 0.5625em;
    border-bottom-right-radius: 0.5625em;
  }

  .add-to-cart-wrapper--with-counter:not(.add-to-cart-wrapper--fullwidth)
    .button-icon-small.plus {
    right: 44px;
    border-bottom: 2px solid var(--color-primary-5);
  }
  .add-to-cart-wrapper--with-counter:not(.add-to-cart-wrapper--fullwidth)
    .button-icon-small.minus {
    border-bottom: 2px solid var(--color-primary-5);
  }

  .add-to-cart-wrapper--fullwidth {
    display: flex;
  }

  .add-to-cart-wrapper--fullwidth.add-to-cart-wrapper--with-counter {
    display: block;
  }
}

.add-to-cart-wrapper div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-to-cart-wrapper button {
  display: inline-block;
  padding: 0;

  text-align: center;
  font-weight: 600;

  color: var(--color-primary);
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
}

.add-to-cart-wrapper .button-icon-small svg {
  padding: 0 6px;
  font-size: 12px;
}

.add-to-cart-wrapper .button-icon-small {
  height: 22px;
  width: 21px;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid var(--color-primary-5);
  height: 100%;
}

.add-to-cart-wrapper .button-icon-small:focus-visible {
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

.add-to-cart-wrapper .button-icon-small.plus {
  border-bottom: 0;
}

.add-to-cart-wrapper .button-icon-small.minus {
  border-bottom: 2px solid var(--color-primary-5);
}

.add-to-cart-wrapper .button-icon-small svg {
  padding: 0 4px;
}

@media (any-pointer: coarse) {
  .add-to-cart-wrapper .button-icon-small {
    height: 44px;
    width: 44px;
    position: absolute;
    top: 0;
    border-right: 2px solid var(--color-primary-5);
    border-left: 2px solid var(--color-primary-5);
  }
  .add-to-cart-wrapper .button-icon-small.plus {
    right: 0;
  }
  .add-to-cart-wrapper .button-icon-small.minus {
    left: 0;
    border-bottom: 0;
    border-top-left-radius: 0.5625em;
    border-bottom-left-radius: 0.5625em;
  }
  .add-to-cart-wrapper--fullwidth .button-icon-small.minus {
    border-top-left-radius: 0.5625em;
    border-bottom-left-radius: 0;
  }
  .add-to-cart-wrapper--fullwidth .button-icon-small.plus {
    border-top-right-radius: 0.5625em;
  }
}

@media (any-pointer: coarse) {
  .add-to-cart-wrapper .button-icon-small {
    border-bottom: 0;
  }
}

.add-to-cart-wrapper .button-add-to-cart[disabled],
.add-to-cart-wrapper[disabled] > div,
.add-to-cart-wrapper[disabled] > input {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.add-to-cart-wrapper[disabled] .tooltip-container {
  opacity: 1;
}

.add-to-cart-wrapper[disabled] button {
  cursor: not-allowed;
  pointer-events: none;
}

.add-to-cart-counter {
  text-align: center;
  appearance: textfield;
  margin: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.add-to-cart-counter:focus   {
  border-color: var(--color-primary-75);
  border-radius: 2px;
  box-shadow: 0 0 0 3px var(--color-primary-2);
  outline: 0;
}

.add-to-cart-counter::-webkit-inner-spin-button,
.add-to-cart-counter::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.add-to-cart-wrapper .button-add-to-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;

  line-height: 24px;
  color: #fff;

  border: 2px solid var(--color-primary-5);
  background-color: var(--color-primary);
  transition: background-color 0.25s;
}

.add-to-cart-wrapper .button-add-to-cart svg {
  font-size: 20px;
}

.add-to-cart-wrapper .button-add-to-cart:hover {
  border-color: var(--color-primary-5);
  background-color: #869DA6;
  color: var(--color-primary);
}

.add-to-cart-wrapper .button-add-to-cart:hover svg {
  fill: var(--color-primary);
}

.add-to-cart-wrapper--fullwidth .button-add-to-cart.button-rounded-right {
  border-radius: 0 0 0.5625em 0.5625em;
}

@media (any-pointer: fine) {
  .add-to-cart-wrapper .button-add-to-cart.button-rounded-right {
    border-radius: 0 0.5625em 0.5625em 0;
  }
}

@media (any-pointer: fine) and (pointer: coarse) {
  .add-to-cart-wrapper .button-add-to-cart.button-rounded-right {
    border-radius: 0 0 0.5625em 0.5625em;
  }
}

.add-to-cart-wrapper .button-add-to-cart.full svg {
  margin-left: 10px;
}
.add-to-cart-wrapper .button-add-to-cart.full,
.add-to-cart-wrapper .trigger.full {
  width: 100%;
  line-height: 1.2;
}

.add-to-cart-wrapper .button-add-to-cart span {
  margin: 0;
}

.add-to-cart-counter {
  height: 44px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.27;
  color: var(--color-primary);
  border: 0;
  width: 44px;
  border-radius: 0.5625em 0 0 0.5625em;
  border: 2px solid var(--color-primary-5);
}

@media (any-pointer: coarse) {
  .add-to-cart-counter {
    width: calc(100% - 2 * 44px);
    display: block;
    margin: 0 auto !important;
    border-radius: 0;
    border-top: 2px solid var(--color-primary-5);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
}

.add-to-cart-wrapper .cart-item-quantity {
  padding-top: 2px;
  margin-right: 15px;
  height: 44px;
  width: 44px;

  font-size: 18px;
  font-weight: bold;
  line-height: 1.27;
  color: var(--color-primary);

  border: none;
}
.add-to-cart-wrapper .cart-item-quantity span:not(:first-of-type) {
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 300;
}

.button-loader-white svg path {
  fill: #fff;
}

.button-loader-wrap {
  position: relative;
}

.button-loader {
  position: absolute;
  top: 24px;
  left: 10px;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .button-loader {
    top: 27px;
    left: 20px;
  }
}

.btn-search {
  position: absolute;
  right: 0;
  cursor: pointer;
  padding: 10.5px 0.75em;
}

.btn-search:focus {
  outline: none;
}

.react-share__ShareButton circle {
  fill: var(--color-primary);
}

.past-order-button:hover {
  border-radius: 9px;
  box-shadow: 0 0 0 3px var(--color-primary-2);
}
