.client-chooser-wrap {
  overflow-y: scroll;
  max-height: 480px;
  border-top: 8px solid white;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -8px;
  padding-left: 16px;
  padding-right: 16px;
  background:
    linear-gradient(#feffff 33%, rgba(254,255,255, 0)),
    linear-gradient(rgba(254,255,255, 0), #feffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(110,110,110, 0.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(110,110,110, 0.2), rgba(0,0,0,0)) 0 100%;
  background-color: #feffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 57px, 100% 57px, 100% 19px, 100% 19px;
}

@media (max-height: 700px) {
 .client-chooser-wrap {
    max-height: calc(100% - 235px);
    max-height: calc(100vh - 235px);
  }
}

@media (max-width: 767px) {
  .client-chooser-wrap {
    max-height: calc(100% - 76px);
    max-height: calc(100vh - 76px);
    padding-bottom: 120px;
  }
}

.client-chooser-text {
  margin-top: 6px;
  margin-bottom: 6px;
  display: block;
}

.client-chooser {
  position: relative;
  width: 100%;
  display: block;
  text-align: left;
  padding: 8px 32px 8px 16px;
  appearance: none;
  border: 1px solid #666666;
  border-radius: 2px;
  line-height: 1.375;
  background-image: linear-gradient(to bottom, white, #f7f7f7);
  cursor: pointer;
}

.client-chooser::after {
  position: absolute;
  right: 14px;
  top: 15px;
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat;
  background-image: url(/static/img/icon-streamline/arrow-button-down.svg);
  pointer-events: none;
}


.client-chooser__filter div {
  padding: 8px 12px;
  border-top: 1px solid var(--color-primary-09);
}

.client-chooser__filter div:hover {
  background-color: var(--color-primary-09) !important;
  cursor: pointer;
}

.client-chooser__results:not(:empty) {
  display: block;
  margin-top: -.5em;
  border: 2px solid var(--color-primary-5);
  box-shadow: 0 0 0 3px var(--color-primary-2);
  border-radius: 9px;
}

.client-list__item {
  display: block;
  border-top: 1px solid var(--color-primary-06);
  width: 100%;
  text-align: left;
  padding: 12px 0;
}

.client-list__item:hover {
  background-color: rgba(0, 58, 79, 0.02);
}

.client-list__item:last-of-type {
  border-bottom: 1px solid var(--color-primary-06);
  margin-bottom: 1em;
}
