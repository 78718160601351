.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
}

.icon svg {
  height: 18px;
  width: 18px;
}

.icon--xs svg {
  height: 8px;
  width: 8px;
}

.icon--smaller svg {
  height: 12px;
  width: 12px;
}

.icon--small svg {
  height: 14px;
  width: 14px;
}

.icon--16 svg {
  height: 16px;
  width: 16px;
}

.icon--medium svg {
  height: 21px;
  width: 21px;
}

.icon--large svg {
  height: 24px;
  width: 24px;
}

.icon--bigger svg {
  height: 32px;
  width: 32px;
}

.icon--l svg {
  height: 48px;
  width: 48px;
}

.icon--xl svg {
  height: 72px;
  width: 72px;
}


.icon--loading {
  display: flex;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(/static/img/loading-Interwind-1s-96px.gif);
  background-size: contain;
}

.icon--sort-alpha-up,
.icon--sort-alpha-down,
.icon--upload,
.button--icon {
  cursor: pointer;
}

.icon-circle {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .icon-circle {
    height: 46px;
    width: 46px;
  }
}

.form-field-icon {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -8px;
  opacity: .8;
}
