.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
}
