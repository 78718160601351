.text-justify {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-italic {
  font-style: italic;
}

.text-danger {
  color: #e05b5b;
}

@media (hover: hover) {
  a.text-danger:hover,
  a.text-danger:focus {
    color: #a71d2a;
  }
}

.text-muted {
  color: rgba(0,58,79,0.65);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-break {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

@media (max-width: 767px) {
  h1 {
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}
