.teaser-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-primary-09);
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.teaser-head__title {
  margin: 0;
  font-weight: 600;
}

h3.teaser-head__title {
  margin-top: 0;
}
