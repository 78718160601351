.order-details-row {
  background-color: rgba(216, 216, 216, 0.2);
  border-bottom: 1px solid var(--color-primary-09);
  padding: 1em;
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .order-details-row {
    padding: 0.5em 1em;
    flex-wrap: nowrap;
  }
}

.order-details-row-item {
  min-width: 50%;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .order-details-row-item {
    min-width: 0;
    max-width: 23%;
    flex: 1;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.order-details-item-image-wrap {
  min-width: 64px;
  max-width: 64px;
  margin-right: 1em;
  margin-bottom: 0.5em;
  float: left;
}
.order-details-item-rest {
  display: flex;
  justify-content: space-between;
}

.order-details-item-rest-item {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .order-details-item-rest {
    border-bottom: 1px solid #f3f6f9;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
  .order-details-item-image-wrap {
    float: initial;
  }
}

.order-details-item-rest-item + .order-details-item-rest-item {
  margin-left: 0.5em;
}

.order-details-item-main {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .order-details-item + .order-details-item {
    border-top: 1px solid var(--color-primary-09);
  }
  .order-details-item-image-wrap {
    min-width: 92px;
    max-width: 92px;
  }
  .order-details-item-main {
    min-width: 140px;
    max-width: 360px;
    align-self: flex-start;
    margin-right: 2em;
    margin-left: 110px;
    margin-bottom: 0;
  }
  .order-details-item-rest {
    min-width: 330px;
  }
}

.order__status--shipped {
  color: #649153;
}

.order__status--processing {
  color: #f5a623;
}

.order__status--invalid {
  color: #e05b5b;
}

.order-details-item-image-and-main {
  min-width: 260px;
}

@media (max-width: 767px) {
  .order-details-item-image-and-main {
    display: flex;
    min-width: none;
  }
  .order-details-item-image-wrap {
    margin-bottom: 0.75em;
  }
}

.order-state {
  display: inline-block;
  border-radius: 1em;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
}

.order-state[data-status="PROCESSING"] {
  background-color: #FEF3C7;
  color: #B45309;
}

.order-state[data-status="SHIPPED"] {
  background-color: #D1FAE5;
  color: #218261;
}

.order-state[data-status="INVALID"] {
  background-color: #fae3e3;
  color: #e05b5b;
}

.order-state[data-status="TEMPORARY"] {
  background-color: var(--color-primary-2);
  color: var(--color-primary);
}
