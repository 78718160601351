.switch {
  position: relative;
  display: inline-block;
  order: 3;
  vertical-align: middle;
  text-align: left;
  font-size: 0.875rem;
}

@media (min-width: 1024px) {
  .switch + .switch {
    margin-left: 0.5em;
  }
}

.switch[hidden] {
  display: none;
}

.switch__button {
  position: relative;
  width: 100%;
  padding-right: 2.25em;
  justify-content: flex-start;
}

.switch__button::after {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -4px;
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat;
  background-image: url(/static/img/icon-streamline/arrow-button-down.svg);
  pointer-events: none;
}
.switch__button--dark::after {
  background-image: url(/static/img/icon-streamline/arrow-button-down-white.svg);
}

.switch__button--beta {
  padding-right: 1.2857125em;
  border-color: var(--color-pink-5);
  color: var(--color-pink-5);
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::after {
    background-image: none;
  }
}

.open .switch__button::after {
  transform: rotateX(180deg);
}

.switch__button-text {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.5em;
  text-align: left;
}

@media (max-width: 1023px) {
  .switch {
    width: 100%;
  }
  .switch__button--cart {
    padding-right: 12px !important;
    display: block;
  }
  .switch__button--cart .switch__button-text {
    width: calc(100% - 52px);
  }
  .switch__button--cart::after {
    display: none;
  }
}

@media (max-width: 639px) {
  .switch__button {
    padding-top: calc(0.375em + 1px);
    padding-bottom: calc(0.375em - 1px);
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .switch {
    width: calc(50% - 0.25rem);
  }
  .switch__button--cart {
    margin-left: 0.5em;
  }
}

@media (min-width: 1024px) {
  .switch__button {
    min-height: 43.4px;
  }
  .switch__button-text {
    max-width: 188px;
  }
}

.dropdown--cart {
  padding: 0 !important;
}

.dropdown--cart__inner {
  overflow-y: auto;
  padding: 12px 16px;
}

@media (max-width: 1023px) {
  .dropdown--cart {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
  .dropdown--cart__inner {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .dropdown--cart {
    width: 500px;
    left: initial;
  }
}

@media (min-width: 1024px) {
  .dropdown--cart {
    width: 420px;
  }
  .dropdown--cart__inner {
    max-height: calc(100vh - 120px);
  }
}

.cart-indicator {
  display: inline-block;
  width: 21px;
  height: 21px;
  font-size: 0.875rem;
  line-height: calc(21 / 16);
  margin-left: 4px;
  border-radius: 50%;
  color: var(--color-primary);
  background-color: white;
  visibility: visible;
  vertical-align: middle;
  text-align: center;
}

.cart-indicator span {
  margin-top: 2px;
  display: block;
  letter-spacing: -0.5px;
}

@media (max-width: 1023px) {
  .cart-indicator {
    width: 19px;
    height: 19px;
  }
}

.cart-dropdown-indicator {
  min-width: 23px;
  height: 23px;
  border-radius: 50%;
  color: white;
  border: 2px solid white;
  background-color: #853d75;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  padding-top: 2px;
  letter-spacing: -0.5px;
}

.cart-action-area {
  box-shadow:
    0 10px 15px 0px rgba(0, 0, 0, 0.1),
    0 4px 6px 0px rgba(0, 0, 0, 0.05);
  margin: 0 -16px;
  padding: 0 16px 12px;
  width: calc(100% + 32px);
  position: sticky;
  top: -12px;
  background-color: white;
  z-index: 1060;
}

.cart-holder {
  font-size: 18px;
  line-height: 1.2;
}

.cart__item + .cart__item {
  border-top: 2px solid var(--color-primary-09);
  padding-top: 0.5em;
}

.item__img {
  min-width: 44px;
}

.item__qty {
  margin-top: 8px;
  margin-bottom: 14px;
  width: 118px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item__qty button {
  height: 32px;
  width: 33px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: linear-gradient(to bottom, white, #f7f7f7);
  border: 2px solid var(--color-primary-2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.border-right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.item__qty .form-field {
  height: 32px;
  padding: 5px;
  text-align: center;
  width: 56px;
  border-radius: 0;
  margin-left: -2px;
  margin-right: -2px;
  border: 2px solid var(--color-primary-2);
}

.item__qty .form-field::placeholder {
  opacity: 1;
  font-weight: bold;
}

.item__price {
  min-width: 76px;
  text-align: right;
}

.cart__items {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0.5em;
}

@media (max-width: 1023px) {
  .cart__items {
    padding-bottom: 120px;
  }
}

.cart-toast {
  position: absolute;
  right: 10px;
  width: 300px;
  padding: 1em;
  margin-top: 0.75em;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(0, 0, 0, 0.05);
  background-color: white;
  z-index: 1;
  border-radius: 9px;
}

@media (min-width: 768px) {
  .cart-toast {
    right: 0;
    min-width: 370px;
  }
}

.cart-toast::before {
  display: block;
  position: absolute;
  top: -10px;
  right: 1em;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 10px 9px;
  border-color: transparent transparent white transparent;
}

.cart-toast::after {
  display: block;
  position: absolute;
  top: -9px;
  right: 1em;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 10px 9px;
  border-color: transparent transparent white transparent;
}

@media (max-width: 1023px) {
  .cart-toast::before,
  .cart-toast::after {
    right: 0;
  }
}
