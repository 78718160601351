/* Styling of src/components/portal/orders/RouteTab.js */
.RouteTab__holder {
  display: inline-block;
}

@media (min-width: 768px) {
  .RouteTab__holder {
    border-bottom: 3px solid var(--color-primary-09);
    height: 29.5px;
  }
}

.RouteTab__item:last-of-type {
  margin-right: 0 !important;
}
