.header {
  position: relative;
  z-index: 1090;
}

.header-mobile-logomark {
  height: 26px;
  width: 61px;
}

@media (max-width: 767px) {
  .header-mobile-logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .header-mobile-logomark {
    display: none;
  }
}

@media (min-width: 1024px) {
  .header {
    height: auto;
    padding-bottom: 0;
  }
  .header-logo {
    width: 226px;
    margin: 0;
    display: inline-block;
    position: relative;
    z-index: 1093;
  }
  .header__bottom {
    position: relative;
    margin-top: 1.5rem;
  }
  .header.sticky a[rel~='home'] {
    margin-top: 0.25em;
  }
  .header.sticky .header__bottom {
    float: right;
    width: calc(100% - 208px);
    margin-top: 0;
  }
  .header.sticky .header__bottom .nav--main {
    display: none;
  }
  .header.sticky .header-logo {
    width: 180px;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .header-logo {
    width: 226px;
  }
}

.header .search-field {
  padding-left: 0.875em;
  padding-right: 32px;
  border-width: 2px;
  position: relative;
}

.header .search-field:focus,
.header .search-field:hover {
  box-shadow: 0 0 0 3px rgba(0,58,79,0.1);
}

.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1083;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.10), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.header.sticky .top-notification {
  display: none;
}

.header .search-bar {
  position: absolute;
  top: 0.25em;
  right: 15px;
  width: calc(100% - 190px);
  transition: width 0.25s ease;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header .search-bar {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 1023px) {
  .header .search-bar .search-field {
    height: 38.6px;
  }
  .header .btn-search {
    padding: 8px .75rem;
  }
  .search-bar:has(.search-field:focus) {
    width: calc(100% - 30px);
  }
}

.search-container {
  margin-top: 7em;
  margin-bottom: 5em;
}

.header.sticky .search-container {
  margin-top: 7em;
}

@media (min-width: 640px) {
  .search-container {
    margin-top: 5em;
  }   
  .header.sticky .search-container {
    margin-top: 5em;
  }
  }

@media (min-width: 1024px) {
  .header .search-bar {
    bottom: .5em;
    top: initial;
    right: 0;
    width: 280px;
  }
  .search-bar:has(.search-field:focus) {
    width: 400px;
  }
  .header.sticky .search-bar {
    left: 0;
    right: initial;
    bottom: 0;
    width: calc(100% - 376px);
  }
  .search-container {
    margin-top: 11em;
    margin-bottom: 5em;
  }
}

@media (min-width: 1024px) {
  .header.sticky .search-bar {
    width: calc(100% - 535px);
  }
}

.header-container {
  padding-top: .5rem;
  padding-bottom: .25rem;
}

@media (min-width: 1024px) {
  .header-container {
    padding-bottom: .5rem;
  }
}

.search-focused {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  height: 100%;
  transition: all .36s ease-in-out;
  min-height: 100vh;
}

@media (min-width: 1024px) {
  .search-focused {
    min-height: 780px;
    max-height: 90vh;      
  }
   .header.sticky .search-focused {
      min-height: 680px;
      height: auto;
   }
}

@media (min-height: 1024px) {
  .search-focused {
    min-height: 940px;
  }
  .header.sticky .search-focused {
    min-height: 840px;
  }
}

.search-focused.visible {
  transform: translateY(0);
}

.search-focused.hidden {
  transform: translateY(-200%);
}

.search-focused.hidden .search-container {
  opacity: 0;
}

.search-focused.visible .search-container {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.search-suggestion-dropdown-overlay {
  overscroll-behavior: none;
}

.search-suggestion-dropdown-overlay.visible {
  position: fixed;
  background: var(--color-primary-7);
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
}

.searched-item {
  margin-left: -.5rem;
  margin-right: -.5rem;
  width: calc(100% + 1rem);
  font-size: 0.9375rem;
}

.searched-item[data-is-last] {
  margin-bottom: 1.5rem !important;
}

@media (hover: hover) {
  .searched-item:hover {
    background-color: var(--color-primary-03);
    opacity: 1;
    transition: background-color 0.25s ease-in-out;
  }
}

@media (max-width: 1023px) {
  .search-order-suggest {
    order: 2;
  }
  .search-order-products {
    order: 1;
  }
  .search-order-content {
    order: 3;
  }
}
