.tooltip-container {
  display: flex;
  flex-direction: row;
  min-width: 280px;
  max-width: 400px;
  padding: 15px 15px 0 15px;
  z-index: 1100;
  background: #fff;
  border: 1px solid var(--color-primary-09);
  border-radius: 9px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.10), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
  transition: opacity 0.3s;
  max-height: 100vh;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .tooltip-container {
    max-width: 100vw;
  }
}

.tooltip-container.error {
  padding: 15px;
  margin: 10px 0;
  background-color: #f6c800;
  border: none;
}

.tooltip-container > div {
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 1.28;
  text-align: left;
}

.tooltip-icon {
  width: auto !important;
}

.tooltip-container > div:not(.tooltip-arrow) {
  flex-direction: column;
}

.tooltip-container .icon--road-sign-warning {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tooltip-container .icon--road-sign-warning svg {
  height: 25px;
  width: 25px;
  margin: 5px 20px 5px 0;
}

.tooltip-container a {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.tooltip-container p {
  margin: 0;
  line-height: 1.28;
}

.form-label .trigger {
  display: inline-block;
}

@media (hover: hover) and (pointer: fine) {
  .form-label .trigger {
    position: relative;
    z-index: 10;
  }
}

.trigger:hover {
  cursor: pointer;
}

  /* .tooltip-arrow {
    height: 1rem;
    position: absolute;
    width: 1rem;
  }
  .tooltip-arrow::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
  .tooltip-arrow::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  } */

.tooltip-arrow[data-placement*='bottom-start'] {
  left: 240px !important;
}

.tooltip-arrow[data-placement*='bottom'] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent silver transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  top: 0;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  top: 1px;
}
.tooltip-arrow[data-placement*='top'] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='top']::before {
  border-color: silver transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  top: 1px;
}
.tooltip-arrow[data-placement*='top']::after {
  border-color: white transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  top: 0;
}
.tooltip-arrow[data-placement*='right'] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='right']::before {
  border-color: transparent silver transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}
.tooltip-arrow[data-placement*='right']::after {
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.tooltip-arrow[data-placement*='left'] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent silver;
  border-width: 0.5rem 0 0.5rem 0.4em;
}
.tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

.tooltip-container.error .tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent #f6c800 transparent;
}
.tooltip-container.error .tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #f6c800 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}
.tooltip-container.error .tooltip-arrow[data-placement*='top']::before {
  border-color: #f6c800 transparent transparent transparent;
}
.tooltip-container.error .tooltip-arrow[data-placement*='top']::after {
  border-color: #f6c800 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
.tooltip-container.error .tooltip-arrow[data-placement*='right']::before {
  border-color: transparent #f6c800 transparent transparent;
}
.tooltip-container.error .tooltip-arrow[data-placement*='right']::after {
  border-color: transparent #f6c800 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.tooltip-container.error .tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent #f6c800;
}
.tooltip-container.error .tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent #f6c800;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}


.only-for-med-box {
  padding: calc(.5em + 1px) 1.2857125em .5em; /* font-related */
}