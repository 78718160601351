.product-list-active-tag {
  display: inline-block;
  background-color: var(--color-primary-06);
  border: 1px solid var(--color-primary-2);
  color: var(--color-primary);
  border-radius: 14px;
  margin: 0.5em 0.5em 0.5em 0;
  line-height: 1.1;
  padding: 8px 12px 7px;
  font-size: 0.875rem;
}

.product-list-active-tag b {
  font-weight: 700;
}

.product-list-active-tag__value {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
