.portal-header {
  background-color: var(--color-primary);
  padding-top: 1rem;
  margin-bottom: 3rem;
  min-height: 126px;
}

.portal-header .button:hover {
  background-color: white;
}

.portal-tabs .no-button {
  color: white;
  padding: 0.5rem 1rem;
  display: block;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.portal-tabs .portal-tab {
  display: inline-block;
}

.portal-tabs .portal-tab:hover {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-color: rgba(255, 255, 255, 0.2);
}

.portal-tab .message-badge-new.active {
  float: right;
  margin-top: 0;
  margin-right: 0;
}

.portal-tabs .no-button.active,
.portal-tabs .no-button[data-active='true'] {
  background: white;
  color: var(--color-primary);
  outline: none;
}

.portal-title {
  color: white;
  margin: 1em 0;
}

.portal-title > .icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.5rem;
}

.portal-title--bottom {
  margin-bottom: 0 !important;
  margin-top: 58px;
}

.portal-subtitle {
  color: white;
  display: block;
}

.portal-tabs {
  margin-left: -.5rem;
}

@media (min-width: 768px) {
  .portal-tabs {
    margin-left: 0;
  }

  .portal-tabs .no-button {
    padding: .75rem 1rem;
  }
}

.portal-tab + .portal-tab {
  margin-left: .5em;
}

.user-list-item .item {
  width: 40%;
  padding-left: 15px;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
}

@media (min-width: 768px) {
  .user-list-item .item {
    width: 20%;
  }
  .user-list-item .item--name {
    width: 25%;
  }
}

.user-list-item .item.action {
  display: flex;
  justify-content: space-between;
  width: 20%;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .user-list-item .item.action {
    width: 15%;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

.user-list-item .item .cart-items {
  flex-direction: column;
}

@media (min-width: 768px) {
  .user-list-item .item .cart-items {
    flex-direction: row;
    margin-right: 1.5rem;
  }
}

.user-list-item .icon--arrow-button-right {
  width: 9px;
}

.message-badge-new {
  width: 9px;
  height: 9px;
  float: left;
  margin-top: 4px;
  margin-right: 9px;
  margin-left: 4px;
  background-color: #d2e750;
  border-radius: 50%;
}

.messaging .tbody {
  border-bottom: 1px solid #e9e9e9;
}

.messaging .table-cell {
  padding: 1em 0 1em 1em;
}

.messaging .tbody .table-row:nth-child(odd) {
  background-color: white;
}

.messaging .table-cell .no-button {
  color: var(--color-primary);
  font-weight: 600;
}

.create-client-link {
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  color: inherit;
  border-bottom: 1px solid var(--color-primary);
}

@media (hover: hover) {
  .create-client-link:hover {
    border-width: 2px;
  }
}