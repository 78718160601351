/*  used for .hero-image in order to
counter next image component injecting overflow: hidden by default
*/

.hero-image-wrap {
  position: relative !important;
  padding-top: 41% !important;
}

.hero-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: none !important;
  width: 100%;
}

@media (min-width: 1210px) {
  .hero-image {
    margin-left: -3em !important;
    margin-right: -3em !important;
    width: calc(100% + 6em) !important;
  }
}

.hero-text {
  padding: 15px;
  margin-top: -50px;
  min-width: 70%;
  min-height: 124px;
  border-radius: 9px;
}

@media (min-width: 768px) {
  .hero-text {
    padding: 2.125em;
    position: absolute;
    bottom: -25px;
    max-width: 594px;
  }
}

.hero-text h1 {
  color: var(--color-green);
  margin: 0;
}

.hero-text h2,
.hero-text h3,
.hero-text h4,
.hero-text h5,
.hero-text h5,
.hero-text p {
  color: var(--color-white);
  margin-bottom: 0;
  margin-top: 0.5em;
}

.hero-product-col {
  padding-left: 12px;
  padding-right: 12px;
  flex-grow: 1;
  margin: 0 auto 24px;
}

.hero-product-col > a {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hero-contact-image {
  border-radius: 100%;
  width: 92px !important;
  height: 92px !important;
  margin-bottom: .75em !important;
  position: inherit !important;
}

.hero-contact-image-wrapper div {
  position: inherit !important;
}
