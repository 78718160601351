.border {
  border: 1px solid var(--color-primary-09) !important;
}

.border-top {
  border-top: 1px solid var(--color-primary-09) !important;
}


.border-menu-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.border-menu-top-dark {
  border-color: #00202d !important;
}

.border-menu-top-blueish-light {
  border-color: #acbcc0 !important;
  margin-top: -1px;
}

.border-menu-top-blueish {
  border-color: #153c4b !important;
  margin-top: -1px;
}

.border-menu-top-light {
  border-color: var(--color-primary-2) !important;
  margin-top: -1px;
}




.border-right {
  border-right: 1px solid var(--color-primary-09) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--color-primary-09) !important;
}

.border-left {
  border-left: 1px solid var(--color-primary-09) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-full {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
