.bookmark-button {
  margin-left: 1em;
}

.bookmark-button .icon {
  padding: 0.375em;
  opacity: 0.5;
  border-radius: 50%;
  background-color: #e5ebed;
  transition: 0.2s background-color ease;
}

.bookmark-button .icon svg {
  height: 15px;
  width: 15px;
}

.bookmark-button[data-active='true'] .icon {
  background-color: #afca0B;
  opacity: 1;
}

.bookmark-button:disabled {
  opacity: 0.3;
}

.bookmark-button[data-error='true'] svg {
  fill: #e05b5b;
}
