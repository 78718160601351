.form-helper {
  margin-top: -0.75em;
  line-height: 1.1;
}

.form-check.radio + .form-check.radio {
  margin-top: 0.25em;
}

.form-check-input:focus-visible {
  outline: 2px solid #79939B;
  outline-offset: 2px;
}

.form-content .wrap-field-container:first-of-type {
  padding-top: 0.875em;
}

.field-container {
  margin-bottom: 1em;
  min-height: 38px;
}

.field-container .select-wrap,
.field-container select {
  width: 100%;
}

form .field-container.required .form-label::after {
  content: '*';
  display: inline-block;
  margin-left: 0.25em;
}



.form .react-datepicker__input-container,
.form .react-datepicker-wrapper,
.react-datepicker-wrapper {
  width: 100%;
  z-index: 100;
}

.react-datepicker {
  position: relative;
  z-index: 1100 !important;
}

@media (min-width: 1024px) {
  .form {
    max-width: 640px;
  }
  .card .form {
    max-width: none;
  }
  .card .form .react-datepicker-wrapper {
    width: 48%;
  }
}

@media (min-width: 640px) {
  .wrap-field-container--half {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .field-container--half {
    width: 48%;
  }
}

.wrap-field-container--quarter {
  display: flex;
  flex-wrap: wrap;
}

.field-container--quarter {
  width: 48%;
}

.field-container--quarter {
  padding-right: 2%;
}
.field-container--quarter:last-child {
  padding-right: 0;
}

@media (min-width: 1024px) {
  .wrap-field-container--third {
    display: flex;
    flex-wrap: wrap;
  }

  .field-container--third {
    width: 32%;
    margin-bottom: 1em;
  }

  .field-container--quarter {
    width: 24%;
  }

  .field-container--third + .field-container--third {
    margin-left: 2%;
  }
}

.form-label {
  display: block;
  margin-bottom: 6px;
  font-size: 0.875rem;
  line-height: 1.15;
  color: rgba(0,58,79,0.65);
}

.form-label.text-danger {
  color: #e05b5b !important;
}

.form-label--hidden {
  display: none;
}

.color-blue .form-label {
  color: var(--color-primary);
}

.font-size-normal .form-label {
  font-size: 1em;
}

.form-check-label a {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.form-field {
  width: 100%;
  color: var(--color-primary);
  border-radius: 0.5625em;
  border: 2px solid var(--color-primary-2);
  padding: calc(.5em + 1px) 2.25em .5em .875em;
  background-color: #ffffff;
  appearance: none;
  line-height: inherit;
  transition: box-shadow 0.16s ease-in-out, border-color 0.12s ease-in-out;
}

.form-field:hover {
  box-shadow: 0 0 0 3px rgba(0, 58, 79, 0.025);
  border: 2px solid rgba(0, 58, 79, 0.4);
}

.form-field:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 58, 79, 0.15);
  border: 2px solid rgba(0, 58, 79, 0.4);
}

.form-field.highlighted {
  box-shadow: 0 0 0 3px rgba(0, 58, 79, 0.15);
  border: 2px solid rgba(0, 58, 79, 0.4);
}

.form-field-show {
  line-height: 1.15;
  color: var(--color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-field--error {
  border-color: #e05b5b;
}

.error-message li::before {
  background-image: url(/static/img/icon-streamline/Aufzaehlung-Punkt-rot.svg);
}


.form-field--error:focus {
  border-color: #e05b5b;
  box-shadow: 0 0 0 3px rgba(224, 91, 91, 0.2);
}

.form-field--error:active {
  background-color: #fff2f6;
}

.form-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  background-color: rgba(0, 58, 79, 0.02);
  border-bottom: 2px solid var(--color-primary-06);
  margin-bottom: 1em;
}

.form-head h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-primary);
}

.form-head .link {
  font-size: 0.875rem;
}

.form-head .icon svg {
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
}

.form-content {
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-bottom: 1.25em;
}

.select-wrap {
  display: inline-block;
  position: relative;
}

.select-wrap::after {
  position: absolute;
  right: 14px;
  top: 50%;
  margin-top: -4.5px;
  content: '';
  display: block;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat;
  background-image: url(/static/img/icon-streamline/arrow-button-down-nav.svg);
  pointer-events: none;
}

.select-wrap select {
  width: 100% !important;
}

.language-select select {
  border: 0;
  background: none;
  padding: 0.375em 0.75em 0.25em;
  margin-left: 0.75em;
}

.language-select::after {
  right: 0;
}

select {
  padding: calc(.5em + 1px) 2.25em .5em .875em;
  appearance: none;
  border-color: var(--color-primary-2);
  border-radius: 0.5625em;
  line-height: 1.375;
  background-color: white;
  cursor: pointer;
  color: var(--color-primary);
  transition-property: border-color, box-shadow;
  transition-duration: 0.16s;
  transition-timing-function: ease-in-out;
  color: rgba(0, 58, 79, 0.8);
}

select:hover {
  border-color: var(--color-primary-5);
  box-shadow: 0 0 0 3px var(--color-primary-2);
}

select:active,
select:focus {
  border-color: var(--color-primary-5);
  box-shadow: 0 0 0 3px var(--color-primary-2);
  outline: none;
}

select::-ms-expand {
  display: none;
}

.hide-show {
  position: absolute;
  bottom: 11px;
  right: 12px;
  outline: none;
}

.icon.password-eye {
  cursor: pointer;
  width: 20px;
  margin-right: 0;
  outline: none;
}

.checkbox label::after {
  content: '';
  display: inline-block;
  height: 6px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
}

.checkbox input[type='checkbox'] {
  opacity: 0;
  margin-left: -0.8em;
  position: absolute;
}

.checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 26px;
  vertical-align: middle;
  cursor: pointer;
}

input:focus-visible + label {
  outline: 2px solid #79939B;
  outline-offset: 2px;
  border-radius: 3px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 16px;
  width: 16px;
  border: 1px solid var(--color-primary);
  left: 0;
  top: 3px;
  border-radius: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid var(--color-primary);
  border-bottom: 2px solid var(--color-primary);

  transform: rotate(-45deg);

  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type='checkbox']:focus-visible + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.can-toggle {
  position: relative;
}
.can-toggle *,
.can-toggle *:before,
.can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.can-toggle input[type='checkbox']:checked ~ label .can-toggle__switch:before {
  content: attr(data-checked);
  left: 0;
}
.can-toggle input[type='checkbox']:checked ~ label .can-toggle__switch:after {
  content: attr(data-unchecked);
}
.can-toggle label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  position: relative;
  margin-right: 32px;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-unchecked);
  position: absolute;
  top: 0;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-checked);
  position: absolute;
  z-index: 5;
  text-align: center;
  background: var(--color-primary);
  transform: translate3d(0, 0, 0);
}
.can-toggle input[type='checkbox'][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type='checkbox']:checked ~ label .can-toggle__switch {
  background-color: white;
}
.can-toggle input[type='checkbox']:checked ~ label .can-toggle__switch:after {
  color: white;
  background-color: var(--color-primary);
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: white;
}
.can-toggle label .can-toggle__switch:before {
  color: #666;
}
.can-toggle label .can-toggle__switch:after {
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: white;
}
.can-toggle input[type='checkbox']:checked ~ label .can-toggle__switch:after {
  transform: translate3d(65px, 0, 0);
}
.can-toggle label {
  font-size: 0.875rem;
}
.can-toggle label .can-toggle__switch {
  height: 30px;
  flex: 0 0 134px;
  border-radius: 15px;
  border: 1px solid var(--color-primary);
  box-sizing: content-box;
}
.can-toggle label .can-toggle__switch:before {
  left: 71px;
  font-size: 0.875rem;
  line-height: 30px;
  width: 71px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 0;
  left: -1px;
  border-radius: 15px;
  width: 71px;
  line-height: 30px;
  font-size: 0.875rem;
}

textarea,
textarea.form-control {
  width: 100% !important;
  min-height: 120px;
  padding: .8125em 2.25em .75em .875em;
  border-radius: 0.5625em;
  background-color: transparent;
  appearance: none;
}

.address-field + .address-field {
  margin-top: 2em;
}

input[type='radio'] {
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 0;
  border-radius: 50%;
  vertical-align: middle;
  box-shadow: inset 0 0 0 1px var(--color-primary);
  transition: box-shadow 0.16s ease-in-out;
}

input[type='radio']:after {
  background-color: white;
  border-radius: 50%;
  content: '';
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  left: 1px;
  top: 1px;
}

input[type='radio']:hover {
  box-shadow: inset 0 0 0 1px var(--color-primary), 0 0 0 3px var(--color-primary-2);
}

input[type='radio']:checked:after {
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  left: 4px;
  top: 4px;
  box-shadow: 0 0 0 1px white, 0 0 0 2px white, 0 0 0 3px white;
}

input[type='checkbox'].after-check {
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 0;
  vertical-align: middle;
  box-shadow: inset 0 0 0 1px var(--color-primary);
  margin-right: 0.5em;
}

input[type='checkbox']:hover {
  box-shadow: inset 0 0 0 1px var(--color-primary), 0 0 0 3px var(--color-primary-2);
}

input[type='checkbox'] {
  transition: box-shadow 0.16s ease-in-out;
}

input[type='checkbox'].after-check:after {
  background-color: white;
  content: '';
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  left: 1px;
  top: 1px;
}

input[type='checkbox'].after-check:checked:after {
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  left: 4px;
  top: 4px;
  box-shadow: 0 0 0 1px white, 0 0 0 2px white, 0 0 0 3px white;
}

.form-content.no-border {
  border-bottom: none !important;
  margin-bottom: -15px;
}

.checkbox input[type='checkbox']:focus + label::before {
  outline: none;
}

.search-field {
  padding: calc(.5em + 1px) 1.2857125em .5em; /* font-related */
  background-color: white;
  border-color: var(--color-primary-2);
}

/* clears the 'X' from Internet Explorer */
.search-wrap input[type='text']::-ms-clear,
.search-wrap input[type='search']::-ms-clear,
.search-field::-ms-clear,
.filter-field::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.search-field::placeholder {
  color: var(--color-primary);
  opacity: .5;
  font-size: 18px;
  letter-spacing: -0.0625rem;
}

.search-clear,
.filter-clear {
  display: none;
  position: absolute;
  margin: -12px 5px 5px 5px;
  padding: 0;
  right: 2.5em;
  top: 50%;
  color: var(--color-primary);
  cursor: pointer;
  border-radius: 50% !important;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  width: 24px;
  height: 24px;
  font-size: 1em;
  line-height: 1.5;
  background-color: #e5ebed !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.search-loading {
  position: absolute;
  margin: -12px 5px 5px 5px;
  right: 2.5em;
  top: .9em;
}

.filter-clear {
  right: .25em;
}

.search-clear:focus,
.filter-clear:focus {
  outline: 1px solid transparent;
  border: 1px solid rgba(0,58,79,0.5);
}

.search-field:placeholder-shown ~ .search-clear,
.filter-field:placeholder-shown ~ .filter-clear {
  display: none;
}

.search-field:not(:placeholder-shown) ~ .search-clear,
.filter-field:not(:placeholder-shown) ~ .filter-clear,
.filter-field:placeholder-shown ~ .filter-clear-always-on {
  display: flex;
}

.search-highlight {
  font-weight: bold;
  position: relative;
}

.search-highlight::after {
  position: absolute;
  left: 0;
  right: 0;
  top: -6px;
  bottom: -4px;
  content: '';
  background-color: rgba(0,58,79,0.1);
  width: auto;
  height: calc(1em + 10px);
  border-radius: 3px;
}

.filter-field {
  padding-left: 2.5rem;
  padding-right: .5em;
  border-color: var(--color-primary-2);
  border-width: 2px;
}

.filter-field:hover,
.filter-field:focus {
  border-color: rgba(0,58,79,0.5);
  box-shadow: 0 0 0 3px rgba(0, 58, 79, 0.1);
}

.filter-field::placeholder {
  color: var(--color-primary);
  opacity: .5;
  letter-spacing: -0.0625rem;
}

input[type='password']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.radio-and-checkbox-content {
  white-space: pre-wrap;
  padding-left: 1.875em;
  margin-top: -1.25em;
  margin-bottom: 1em;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .radio-and-checkbox-content {
    font-size: 1.125rem;
  }
}

form label em {
  color: #ba8b00;
}

.form-address {
  white-space: pre-wrap;
  margin-top: 0.5em;
  line-height: 24px;
  color: var(--color-primary);
}

.select-value-container {
  display: flex;
  color: var(--color-primary);
  border-radius: 9px;
  cursor: pointer;
  border: 2px solid var(--color-primary-2);
  min-height: 43.4px;
}

.select-value-text {
  display: flex;
  align-items: center;
  padding: .5em 2em .5em 2.25em;
  cursor: pointer;
  line-height: 1.125;
}

.select-container {
  position: absolute;
  z-index: 9999;
  top: 2em;
  list-style-type: none;
  padding: .5em 0 .5em 1em;
  border: 2px solid var(--color-primary-2);
  border-radius: 9px;
  background-color: white;
  cursor: pointer;
  padding: 0;
}

.select-value-container:hover,
.select-value-container:focus {
  border: 2px solid var(--color-primary-5);
  box-shadow: 0 0 0 3px var(--color-primary-2);
}

.select-option {
  display: none;
  height: 44px;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  padding: .5em 0 .5em .25em;
  margin-left: 2.125rem;
  line-height: 1;
  letter-spacing: -.25px;
  font-size: .875rem;
}

.select-option:hover {
  background-color: var(--color-primary-06) !important;
}

.select-visible {
   visibility: visible;
}

.select-hidden {
   visibility: hidden;
}

.select-option::before, 
.select-value-text::before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 8px;
  border: 1px solid #d3d3d3;
  background-image: none;
}

.select-value-text::before {
  margin-top: 0;
  position: absolute;
  left: .75rem;
}
