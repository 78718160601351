/* Styling of src/components/portal/orders/Order.js */
.Order__label,
.OrderItem__label {
  font-size: 0.875rem;
  margin-bottom: 4px;
}

.Order__label {
  color: rgba(0,58,79,0.65);
}

.Order__status {
  white-space: nowrap;
}

.Order__status[data-status='SHIPPED'] {
  color: #649153;
}

.Order__status[data-status='PROCESSING'] {
  color: #f5a623;
}

.Order__status[data-status='INVALID'] {
  color: #e05b5b;
}