.v-step-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.v-step-number {
  display: inline-block;
  width: 29px;
  height: 29px;
  line-height: 1.7;
  margin-right: 0.5rem;
  background-color: white;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  text-align: center;
  font-size: 1rem;
  position: relative;
  z-index: 1;
}

.v-step-number:after {
  content: attr(data-num);
}

.v-step--complete .v-step-number:after {
  content: '\2713';
}

.v-step-content {
  padding-left: 0.5rem;
  margin: 2rem 0 2rem 29px;
}

@media (min-width: 768px) {
  .v-step-content {
    padding-left: 2rem;
  }

  .v-step-number {
    margin-right: 2rem;
  }
}
