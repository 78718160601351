/* fix for when tables with a width are in product description */
.paragraph table {
  max-width: 100%;
}

.sigCanvas {
  border: 1px solid currentColor;
}

.wizard-navigation-step.active .stepper-number {
  background-color: var(--color-primary);
  color: white;
}

.product-inactive {
  background-color: #fff2ca;
  color: #ba8b00;
  border: 1px solid #ffc107;
  border-radius: 2px;
}

.cutting-info {
  display: inline-block;
  padding: .375em .75em;
  background-color: #dae49b;
  border: 1px solid #afca0b;
  border-radius: 0.5em;
  font-size: 0.875rem;
  font-weight: normal;
}

@media (hover: hover) {
  .button:hover .icon--diagram-arrow-right,
  .button:hover .icon--Pfeil-rechts,
  a:hover .icon--Pfeil-rechts,
  .no-button:hover .icon--Pfeil-rechts,
  .button:hover .icon--arrow-button-right,
  .link:hover .icon--arrow-button-right {
    transform: translate(3px);
    transition: transform 0.16s ease;
  }
  .contact-option:hover .icon {
    transform: scale(1.1);
    transition: transform 0.16s ease;
  }
}

.arrow-long {
  width: 15px;
  height: 18px;
}

#variants::before {
  display: block;
  content: '';
  margin-top: -250px;
  height: 250px;
  visibility: hidden;
}

.grecaptcha-badge {
  display: none !important;
}

.image-gallery-icon {
  filter: drop-shadow(0 1px 2px var(--color-primary)) !important;
}

.image-gallery-icon:hover {
  color: var(--color-primary) !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border-color: var(--color-primary) !important;
}
