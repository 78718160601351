.us-dz {
  border: 2px dashed var(--color-primary);
  padding: 2rem;
  height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  transition: background 0.25s ease-in;
  border-radius: 3px;
  position: relative;
  margin-bottom: 2.5em;
  margin-top: 1em;
}

.us-dz--compact {
  height: 5rem;
}

@media (hover: hover) {
  .us-dz:hover {
    background: #f9f9f9;
  }
}

.us-dz--compact .us-dz-ph {
  position: relative;
  top: -0.5rem;
}

.us-dz-button {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: -100px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

@media (hover: hover) {
  .us-dz-button:hover {
    background-color: white;
  }
}

.us-dz-files {
  padding: 0 0 2rem 0;
  font-weight: bold;
}

.us-dz-file {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.us-dz-file .icon {
  margin-left: 1rem;
}

.us-dz-file .icon svg {
  width: 0.8rem;
  height: 0.8rem;
}
