@media (min-width: 999px) {
  .product-list--compact .product-matrix {
    width: auto;
  }
}

.product-matrix {
  display: flex;
  flex-wrap: wrap;
  margin: 48px auto 0;
  padding-bottom: 8px;
}

.product {
  display: block;
  outline: 1px solid transparent;
  text-align: left;
  position: relative;
  transition:
    outline .3s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow .5s cubic-bezier(0.4, 0, 0.2, 1),
    border-radius .3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 479px) {
  .product {
    width: 100%;
  }
  .product + .product {
    border-top: 1px solid var(--color-primary-09);
  }
}

@media (min-width: 480px) {
  .product-matrix {
    width: 100%;
  }
  .product-matrix .product {
    width: 50%;
  }
  .product {
    border-left: 1px solid var(--color-primary-09);
  }
  .product-matrix .product:nth-child(2n + 2) {
    border-right: 1px solid var(--color-primary-09);
  }
}

@media (min-width: 999px) {
  .product-matrix .product {
    width: 33.33333%;
  }
  .product-matrix .product:nth-child(2n + 2) {
    border-right: 1px solid transparent;
  }
  .product-matrix .product:nth-child(3n + 3) {
    border-right: 1px solid var(--color-primary-09);
  }
}

.product__action-bar {
  position: absolute;
  top: 16px;
  right: 12px;
}

@media (any-pointer: fine) {
  .product:hover,
  .product:focus {
    outline: 2px solid rgba(0, 58, 79, 0.4);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.10), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05);
    border-color: transparent;
    background-color: #ffffff;
    z-index: 1;
    border-radius: 9px;
  }
  .product .button {
    opacity: 0;
    visibility: hidden;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .product:hover .button,
  .product:focus .button {
    opacity: 1;
    visibility: visible;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@media (any-pointer: coarse) {
  .product:hover,
  .product:focus {
    outline: 0;
  }
  .product .button {
    opacity: 1;
    visibility: visible;
  }
}

.product__image {
  display: block;
  margin: 0 auto 12px;
  height: 130px;
  align-self: center;
}

@media (min-width: 768px) {
  .product__description {
    min-height: 68px;
  }
}

/* this helps alignment when prices and availibility are loading */
.product__availibility_price {
  height: 20px;
}

.product__availability-icon {
  min-width: 15px;
  height: 15px;
}

.product__availability-icon.is-available {
  background-color: #d2e750;
}

.product__availability-icon svg {
  width: 10px !important;
  min-width: 0 !important;
  height: 10px !important;
}

.product__availability-icon.is-unavailable {
  background-color: #ffc107;
  display: inline-block;
  vertical-align: middle;
}

.product__availability-icon.is-deleted {
  background-color: #888888;
  display: inline-block;
  vertical-align: text-top;
}
