.us-dropdown {
  position: relative;
  min-width: 120px;
  border-radius: 9px;
}

.us-dropdown--small .us-dropdown-ph {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.us-dropdown--open .us-dropdown-ph {
  outline: none;
}

.us-dropdown-ph {
  display: flex;
  padding-right: 1em;
  font-size: 1rem;
}

.us-dropdown-ph:hover {
  box-shadow: 0 0 0 2px var(--color-primary-2);
  border-color:rgba(0, 58, 79, 0.4);
}

.us-dropdown-ph:focus-visible,
.us-dropdown-ph:active {
  box-shadow: 0 0 0 2px var(--color-primary-2);
  border-color:rgba(0, 58, 79, 0.4);
  outline: 0;
}


.us-dropdown-ph-text {
  flex: 1 1 auto;
  padding-right: 0.5em;
  cursor: pointer;
}

.us-dropdown-ph-icon .icon {
  width: 9px;
  height: 9px;
}

.us-dropdown-box {
  max-width: 320px;
  position: absolute;
  background: white;
  z-index: 999;
  left: 0;
  right: 0;
  top: calc(100% + 6px);
  box-shadow: 0 0 0 3px #F1F3F5;
  border-radius: 9px;
  border: 2px solid rgba(0, 58, 79, 0.3);
  transition-property: box-shadow;
  transition-duration: 0.16s;
  transition-timing-function: ease-in-out;
}

.us-dropdown--disabled .us-dropdown-box:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
  background: white;
  z-index: 9999;
  content: '';
}

.us-dropdown-header,
.us-dropdown-content:not(last-child) {
  border-bottom: 1px solid var(--color-primary-09);
}

.us-dropdown-content {
  max-height: 320px;
  overflow-x: auto;
}

.us-dropdown-content:empty {
  padding: 1rem;
}

.us-dropdown-header,
.us-dropdown-footer {
  padding: 0.75rem 0.5rem;
  position: relative;
}

.us-dropdown-footer .button {
  font-size: 0.875rem;
}

.us-dropdown-item {
  display: flex;
  padding: 0.75em;
  width: 100%;
  transition: background-color 0.24s ease-in-out;
  font-size: 0.875rem;
}

.us-dropdown-item:hover {
  background-color: rgba(0, 58, 79, 0.05);
}

.us-dropdown-item:active {
  background-color: rgba(0, 58, 79, 0.1);
}

.us-dropdown-item--selected:hover,
.us-dropdown-item--selected {
  background-color: rgba(0, 58, 79, 0.1);
}

.us-dropdown-item--disabled {
  color: lightgrey;
}

.us-dropdown-item--section-title {
  color: var(--color-primary) !important;
  font-weight: bold;
  border-bottom: 1px solid lightgrey
}

.us-dropdown-item--section-title:hover {
  background: white;
  cursor: default;
}

.us-dropdown-item-text {
  flex: 1 1 auto;
}
