.error-area {
  background-color: var(--color-primary);
  color: #d2e750;
  position: relative;
  height: 100vh;
}

.error-area input {
  background-color: white;
  color: var(--color-primary);
}

.error-icon svg {
  height: 64px !important;
  width: 64px !important;
  transform: scaleX(-1);
}

.color-bg-blue .icon--road-sign-warning svg {
  width: 48px;
  height: 48px;
}

.error-code {
  font-size: 10rem;
  font-weight: bold;
  text-shadow:
  0px 4px 0px #4d4d4d,
  0px 14px 10px rgba(0,0,0,0.15),
  0px 24px 2px rgba(0,0,0,0.1),
  0px 34px 30px rgba(0,0,0,0.1);
}
