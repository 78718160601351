@font-face {
  font-family: 'PlutoSans-Regular';
  font-weight: 400;
  src: local('PlutoSans-Regular-adapted-R'),
    url('/static/fonts/PlutoSans-Regular-adapted-R.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'PlutoSans-Medium';
  font-weight: 600;
  src: local('PlutoSans-Medium-adapted-R'),
    url('/static/fonts/PlutoSans-Medium-adapted-R.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Pluto-Regular';
  font-weight: 400;
  src: local('Pluto-Regular-adapted-R'),
    url('/static/fonts/Pluto-Regular-adapted-R.otf')
    format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Pluto-Medium';
  font-weight: 600;
  src: local('Pluto-Medium-adapted-R'),
    url('/static/fonts/Pluto-Medium-adapted-R.otf')
    format('opentype');
  font-display: swap;
}
