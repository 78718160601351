.nav--main {
  display: none;
}

@media (min-width: 1024px) {
  .nav--main {
    display: block;
    padding-bottom: 13px;
    margin-left: -0.75em;
  }

  .nav--main__item {
    padding-right: 0.5em;
    padding-left: 0.5em;
    position: relative;
    font-size: 1rem;
  }

  .nav--main__item[data-in-navigation-path='true'],
  .nav--main__item[data-in-hover-path='true'] {
    position: relative;
  }

  .nav--main__item:hover::after,
  .nav--main__item[data-in-navigation-path='true']::after,
  .nav--main__item[data-in-hover-path='true']::after {
    content: '';
    display: block;
    position: absolute;
    left: 0.5em;
    right: 0;
    width: calc(100% - 1em);
    height: 4px;
    background-color: #dae49b;
    bottom: -1em;
  }

  .nav--main__item[data-in-navigation-path='true']::after,
  .nav--main__item[data-in-navigation-path='true']:hover::after {
    background-color: var(--color-primary);
  }
}

.nav--main__mega {
  display: flex;
  position: absolute;
  left: -1.5em;
  right: -1.5em;
  top: 36px;
  width: calc(100% + 3em);
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  transition-delay: 150ms;
}

.nav--main__item[data-in-hover-path='true'] + .nav--main__mega {
  visibility: visible;
  opacity: 1;
  overflow: visible;
  background-color: white;
}

@media (min-width: 1024px) {
  .nav--main__item {
    padding-left: 0.625em;
    padding-right: 0.75em;
    padding-bottom: 15px;
  }

  .nav--main__item:hover::after,
  .nav--main__item[data-in-navigation-path='true']::after,
  .nav--main__item[data-in-hover-path='true']::after {
    width: calc(100% - 1.375em);
    left: 0.625em;
    bottom: -1.5px;
  }
}

.mega-link {
  display: block;
  padding-top: 0.375em;
  padding-right: 1.75em;
  padding-bottom: 0.375em;
  padding-left: 1.5em;
  cursor: pointer;
  font-size: 1rem;
}

.mega-link.has-arrow {
  position: relative;
}

.mega-link.has-arrow::after {
  position: absolute;
  right: 1em;
  top: calc(1em - 4px);
  content: '';
  background-image: url(/static/img/icon-streamline/arrow-button-right.svg);
  display: block;
  width: 8px;
  height: 8px;
  background-repeat: no-repeat;
  opacity: 0.27;
}

.mega-link:first-child {
  margin-top: 1em;
}

.mega-link:last-child {
  margin-bottom: 1em;
}

.mega-link[data-in-navigation-path='true'] {
  background-color: rgba(0, 58, 79, 0.05);
}

.mega-link:hover,
.mega-link[data-in-hover-path='true'],
.mega-link:focus {
  background-color: #ecf2d0;
}

.mega-link:hover.has-arrow::after {
  opacity: 1;
}

.mega-col {
  width: 33%;
  display: inline-block;
  border-right: 1px solid #d9e2e5;
}

.mega-col-last {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border: 0;
}
