.mobile-menu-holder[data-is-open=true] {
  visibility: visible;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1120;
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  visibility: visible;
  background-color: rgba(255,255,255,.4);
  transform: translate3d(-500px, 0, 0);
  transition-property: transform, visibility, background-color;
  transition-timing-function: cubic-bezier(.61, .01, .42, 1);
  transition-duration: .36s;
  z-index: 1110;
}

.mobile-menu-holder[data-is-open=true] .mobile-menu {
  background-color: var(--color-primary);
  color: #ffffff;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  width: 300px;
  height: 100%;
  opacity: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
}



.mobile-menu-close {
  visibility: hidden;
  height: 0;
  opacity: 0.1;
  transition: opacity .36s cubic-bezier(.61, .01, .42, 1);
}

.mobile-menu-holder[data-is-open=true] .mobile-menu-close {
  overflow: hidden;
  visibility: visible;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.5);
  opacity: 1;
  backdrop-filter: blur(2px);
}
