.call-back-form {
  position: relative;
  padding: 2em 0;
  background-color: white;
  display: none;
  z-index: 8;
}
.call-back-form.is-active {
  display: block;
}

.call-back-form::before {
  position: absolute;
  top: -30px;
  right: 50%;
  margin-right: -212px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 30px;
  border-color: transparent transparent #ffffff transparent;
}

@media (min-width: 1024px) {
  .call-back-form {
    padding: 3em 15em;
    margin: 0 auto;
  }
  .popup .call-back-form {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .popup .call-back-lead {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .call-back-form::before {
    margin-right: -30px;
  }
}
