.article-list {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
}

.article-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  user-select: none;
  font-weight: 400;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.article-item--heading {
  padding-bottom: 2rem;
}

.article-item-header {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}

.article-item-subheader {
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.875rem;
  color: #979797;
}

.article-item-quantity {
  height: 32px;
  width: 48px;
}

.article-item-text {
  flex: 1 1 auto;
}

.article-item-right {
  flex: 0 0 auto;
}

.article-item-right .button--icon {
  display: inline-block;
  width: 0.8rem;
  margin-left: 1rem;
}

.article-item-right .button--icon:hover {
  opacity: 1;
}

.article-handle {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z" fill="currentColor"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.25;
  margin-right: 20px;
  cursor: move;
}
