
.favorites-select-wrapper .us-dropdown {
  min-width: 250px;
  max-width: 320px;
}

.favorites-dropdown-loading {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.favorites-dropdown-loading .icon {
  width: 40px;
  height: 40px;
  transform: scale(0.5);
  min-width: auto;
}
