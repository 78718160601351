.checkout-overview-total {
  padding: 0.5em 1.5em;
  font-size: 0.875rem;
}

.checkout-overview-total.first {
  padding-top: 1em;
  border-top: 2px solid var(--color-primary-09);
}

.checkout-overview-total.total {
  background-color: var(--color-primary-06);
  border-top: 2px solid var(--color-primary-09);
  border-bottom: 2px solid var(--color-primary-09);
  padding: 1.5em;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 0.5em;
}

.checkout-overview-total.total div:nth-of-type(2) {
  font-size: 16px;
}

.checkout-info {
  border: 1px solid var(--color-primary-2);
  padding: 1em 1.25em;
  margin-bottom: 40px;
}

.checkout-info h5 {
  margin: 0;
}

.checkout-overview-terms {
  margin-bottom: 2rem;
}

.checkout-overview-terms .field-container {
  margin-bottom: 0;
}
